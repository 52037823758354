<template>
  <div class="admin-layout__list-heading">
   <slot/>
  </div>
</template>

<script>
export default {
name: 'ListHeading'
}
</script>

<style lang="less" scoped>
  .admin-layout__list-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    height: 70px;
  }
</style>