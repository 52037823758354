<template>
  <div class="filter-block">
    <a-form :form="form">
      <a-button class="filter-btn" @click="setActive(!visible)">
        {{ $t('basics.filter') }}
        <i class="anticon">
          <filters alt="filter-ico" />
        </i>
      </a-button>
      <popover
          :active="visible"
          @blur="setActive(false)"
      >
        <div class="filter-content">
          <div class="filter-content-items">
            <a-form-item class="filter-content-item" v-if="getRole === 1">
              <label>{{label ? label : $t('basics.company')}}</label>
              <a-select
                  @change="selectOrganization"
                  size="large"
                  style="width: 220px"
                  :placeholder="$t('basics.select_organization')"
                  v-decorator="[`select-company`]">
                <a-select-option v-if="withEmptyOrganization" value="0">
                  {{ $t('bases.no_organization') }}
                </a-select-option>
                <a-select-option v-for="item in organizations" :value="item.id" :key="item.id">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item class="filter-content-item">
              <label>{{ checkboxLabel() }}</label>
              <a-checkbox-group
                  @change="onChange"
                  :options="checkboxes"
                  v-decorator="['checkbox-group']"/>
            </a-form-item>
          </div>
          <div class="filter-buttons">
            <button class="filter-clear" @click="clearFilters">{{ $t('basics.clear_filters') }}</button>
            <button class="filter-apply" @click="applyFilters">{{ $t('basics.apply_filters') }}</button>
          </div>
        </div>
      </popover>
    </a-form>
  </div>
</template>

<script>
import Popover from "@/components/Popover";
import filters from "@/assets/icons/Filter.svg?inline";

export default {
  name:'ListFilter',
  props: {
    organizations: {},
    checkboxes: {},
    label: {},
    filteredType: {},
    withEmptyOrganization: {type: Boolean, default: false}
  },
  data() {
    return {
      visible: false,
      organization: false,
      organizationStatus: [],
      form: this.$form.createForm(this, { name: 'filter-form' })
    }
  },
  components: {
    Popover,
    filters
  },
  computed: {
    getRole() {
      return parseInt(this.$store.getters['getRole']);
    }
  },
  methods: {
    checkboxLabel() {
      switch (this.filteredType) {
        case "Base":
          return this.$t('basics.filter_by_base');
        case "Pico":
          return this.$t('basics.filter_by_pico');
        case "User":
          return this.$t('basics.filter_by_user');
        default:
          return this.$t('basics.filter_by_organization')
      }
    },
    onChange(value){
      this.organizationStatus = value
    },
    clearFilters() {
      this.organization = false;
      this.form.resetFields();
      this.$emit('filterBy',{});
      this.setActive(false)
    },
    selectOrganization(value) {
      this.organization = value
    },
    setActive(value) {
      this.visible = value;
    },
    applyFilters() {
      this.setActive(false)
      const filterParams = {
        ...(this.organizationStatus.length ? { organizationStatus: this.organizationStatus } : {}),
        ...(this.organization ? { organization: this.organization } : {})
      }
      this.$emit('filterBy', filterParams)
    }
  }
}
</script>

<style lang="less">
.filter-block {
  .ant-checkbox-group-item {
    display: flex;
    align-items: center;
  }

  .ant-checkbox-group {
    height: 40px;
    display: flex;
    align-items: center;
  }
}

</style>

<style lang="less" scoped>
.filter-btn {
  color: #8F9AA8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 5px 10px ;

  .anticon {
    margin: 0 -2px 0 2px;
  }
}

.filter-buttons {
  height: 40px;
  display: flex;
  align-items: center;

  .filter-clear {
    color:  rgba(64, 69, 78, 0.6)
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0;
    margin-left: 25px;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}

.filter-content {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  &-items {
    display: flex;
  }

  &-item {
    margin: 0 70px 0 0;

    label {
      display: block;
      margin-bottom: 5px;
    }
  }
}
</style>
