<template>
  <h1 class="title"><slot/></h1>
</template>

<script>
export default {
name: 'ListTitle'
}
</script>

<style lang="less" scoped>
  .title {
    font-size: 22px;
    margin: 0;
  }
</style>