import { i18n } from '../i18n'
export const  defaulVeriables = [
    { label: i18n.t('basics.status.active'), value: 1 },
    { label: i18n.t('basics.status.inactive'), value: 2 },
    { label: i18n.t('basics.status.deleted'), value: 3 },
];
export const  defaulVariablesUsers = [
    { label: i18n.t('basics.status.active'), value: 1 },
    { label: i18n.t('basics.status.inactive'), value: 2 }
];
export const  defaulVariablesPicos = [
    { label: i18n.t('picos.locked'), value: 2 },
    { label: i18n.t('picos.unlocked'), value: 1 },
    { label: i18n.t('picos.deleted'), value: 3 },
];

export const picoLimit = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export const timeUnits = [i18n.t('basics.time_units.m'), i18n.t('basics.time_units.h'), i18n.t('basics.time_units.d')]
export const metricUnits = ['km', 'm']