<template>
  <div class="modal-wrapp" v-if="active">
    <div class="modal-block">
      <div class="modal-header" :class="{'modal-header-border':headerBorder}">
        <slot name="modalHeader"/>
      </div>
      <div class="modal-body" :style="{padding: bodyPadding}">
        <slot name="modalBody"/>
      </div>
    </div>
    <div class="modal-cover" @click="onClose"></div>
  </div>
</template>

<script>

export default {
  name: 'MainModal',
  props: ['active', 'headerBorder', 'bodyPadding'],
  methods: {
    onClose(){
      this.$emit('onClose')
    }
  }
}
</script>

<style lang="less" scoped>
.modal-header {
  padding: 25px 30px;

  &-border {
    border-bottom: 1px solid rgba(64, 69, 78, .2);
  }
}
.modal-wrapp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.26);
}

.modal-block {
  width: 100%;
  height: 100%;
  background: white;
  z-index: 999;
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
}

.modal-body {
  height: 100%;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
}

.modal-cover {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.back-btn {
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  height: auto;

  .anticon span {
    margin-left: 3px;
  }
}
</style>