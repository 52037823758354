<template>
  <div v-if="paginationData.current_page" class="main-table-pagination">
    <div v-if="paginationData.total!=0">{{$t('basics.showing') + ' ' + paginationData.from + ' ' + $t('basics.to') + ' ' + paginationData.to + ' ' + $t('basics.of') + ' ' + paginationData.total + ' ' + $t('basics.entries')}}</div>
    <a-pagination
        v-if="paginationData.total!=0"
        :current="defaultPageNumber"
        :page-size="paginationData.per_page"
        :total="paginationData.total"
        :item-render="itemRender"
        @change="onChange"/>
  </div>
</template>

<script>

export default {
  name:'Pagination',
  props: {
    defaultPageNumber: {
      type: Number,
      required: true,
      default: 1
    },
    paginationData: {
      type: Object,
      required: false,
      default: null
    }
  },
  methods: {
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>{this.$t('basics.previous')}</a>;
      } else if (type === 'next') {
        return <a>{this.$t('basics.next')}</a>;
      }
      return originalElement;
    },
    onChange(value) {
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="less">
.ant-pagination {
  border: 1px solid #ececed;
  border-radius: 30px;
  padding: 0 12px;

  &-item {
    border-bottom: none;
    border-top: none;
    border-color: #dbdbdd;
    margin-right: -1px;
    border-radius: 0;
    min-width: 30px;
    height: 30px;

    &:focus,
    &:hover {
      border-color: #dbdbdd;
    }

    &-active {
      border-color: #40454E;
      position: relative;
      z-index: 1;

      &:focus,
      &:hover {
        border-color: #40454E;
      }
    }
  }

  &-next {
    margin-left: 8px;
  }
}
</style>

<style lang="less" scoped>
.main-table-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 34px 0 -6px;
}
</style>
