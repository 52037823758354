<template>
  <a-auto-complete
      class="search"
      allow-clear
      :data-source="dataSource"
      :placeholder="placeholderText"
      style="width: 400px"
      @search="onSearch"
      @select="onSelect">
    <a-input>
      <a-icon slot="suffix" type="search" />
    </a-input>
  </a-auto-complete>
</template>

<script>
export default {
  name: 'Search',
  props: ['placeholderText'],
  data() {
    return {
      dataSource: [],
    };
  },
  methods: {
    onSearch(value) {
      this.$emit('select', value)
    },
    onSelect(value) {
      this.$emit('select', value)
    }
  }
}
</script>

<style lang="less">
  .ant-input-search {
    margin: 0 20px 10px 0;

    .ant-input {
      font-size: 13px;

      &::placeholder {
        color: #40454E;
      }
    }
  }

  .search {
    margin-bottom: 10px;

    .ant-select-selection__clear {
      opacity: 1;
      right: 10px;
      width: 15px;
      height: 15px;
      color: #40454E;
    }
  }
</style>