<template>
  <div
    :class="{PopoverMenu: true, PopoverMenuActive: active}"
  >
    <span v-if="active" class="cover" @click.stop="$emit('blur', $event)" />
    <div class="slotWrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popover",
  props: ["active"]
};
</script>

<style lang="less" scoped>
.PopoverMenu {
  position: absolute;
  overflow: hidden;
  max-height: 0;
  background: white;
  left: 0;
  top: 45px;
  width: 100%;
  box-shadow: 2px 2px 15px rgba(118, 122, 130, 0.15);
  transition: max-height 0s ease-out;
  display: flex;
  z-index: 2;
  flex-direction: column;
  border-radius: 8px;

  .slotWrapper {
    z-index: 10000;
    position: relative;
    //margin: 4px;
    //box-shadow: 2px 2px 15px rgba(118, 122, 130, 0.15);
    padding: 30px 30px 50px;
  }
}

.PopoverMenuActive {
  display: flex;
  max-height: 500px;
  transition: max-height 0.15s ease-in;
}

.cover {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}
</style>