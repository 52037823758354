
export function formatDate(value) {
    var dateObj = new Date(value),
        month = ("0" + (dateObj.getUTCMonth() + 1)).slice(-2),
        day = ("0" + dateObj.getUTCDate()).slice(-2),
        year = dateObj.getUTCFullYear();

    let newdate = day + "/" + month + "/" + year;
    return newdate
}

export function formatDateUserInfoTable(value) {
    var dateObj = new Date(value * 1000),
        month = ("0" + (dateObj.getUTCMonth() + 1)).slice(-2),
        day = ("0" + dateObj.getUTCDate()).slice(-2),
        year = dateObj.getUTCFullYear();

    let newdate = day + "/" + month + "/" + year;
    return newdate
}