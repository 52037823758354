<template>
  <div class="size-changer">
    <span>{{ $t('basics.entries_per_page') }}</span>
    <a-select
        :defaultValue="defaultPageSize"
        :placeholder="$t('basics.select_person')"
        option-filter-prop="children"
        style="width: 65px"
        @change="handleChange"

    >
      <a-select-option v-for="item in tableSizeChanger" :value="item" :key="item">
        {{item}}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  name: 'TableSizeChanger',
  data() {
    return {
      tableSizeChanger: [ 12, 24, 36]
    }
  },
  props: ['defaultPageSize'],
  methods: {
    handleChange(value) {
      this.$emit('select', value)
    },
  }
}
</script>

<style lang="less" scoped>
  .size-changer {
    display: flex;
    align-items: center;
    margin-right: 8px;

    span {
      margin-right: 15px;
    }

    .ant-select {
      color: #8F9AA8;
      margin: 0;
    }
  }
</style>