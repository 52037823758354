import { render, staticRenderFns } from "./TableSizeChanger.vue?vue&type=template&id=29ec7604&scoped=true&"
import script from "./TableSizeChanger.vue?vue&type=script&lang=js&"
export * from "./TableSizeChanger.vue?vue&type=script&lang=js&"
import style0 from "./TableSizeChanger.vue?vue&type=style&index=0&id=29ec7604&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29ec7604",
  null
  
)

export default component.exports